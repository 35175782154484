<template>
	<div :class="['button', modifier, disabled]" tabindex="0">{{ label }}</div>
</template>
<script>
export default {
  name: 'Button',
  props: {
    label: String,
    modifier: {
      default: '',
      type: String
    },
    disabled: {
      default: '',
      type: String
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_button";
</style>