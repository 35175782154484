<template>
  <div>
    <section>
      <div class="funnel-wrapper">
          <p class="qr-header">Scan the QR code and download TapeACall</p>
          <span>iOS</span>
          <div>
             <img src="@/assets/images/download-images/tapeacall-ios-qr-code.png">
          </div>
          <span>Android</span>
          <div>
            <img src="@/assets/images/download-images/tapeacall-android-qr-code.png">
          </div>
      </div>
    </section>

    <CardsComponent :cards="cards" v-if="activeStep === 1" />

    <Modal v-if="confirmLegal" @close="confirmLegal = false" @confirm="acceptUpdatedTerms()">
      <template v-slot:header>
        <p class="modal-copy-text" v-html="$t('funnel.we_updated_legal')"></p>
      </template>
    </Modal>

  </div>
</template>

<script>
import CardsComponent from '@/components/CardsComponent'
import Modal from '@/components/ModalComponent'
import braintree from 'braintree-web'
import paypal from 'paypal-checkout'
import firebase from 'firebase/app'
import 'firebase/auth'
import i18n from '../i18n'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import $ from 'jquery'
import { mapActions, mapGetters, mapMutations } from 'vuex'
const LTO = 'TapeACall-LTO'

export default {
  name: 'Funnel',
  props: {
    title: String
  },
  components: {
    CardsComponent,
    Modal,
  },
  data() {
    return {
      activeStep: 1,
      totalSteps: 3,
      selectedPlan: null,
      phone: '',
      formattedPhoneNumber: '',
      appVerifier : '',
      firebaseOTP : '',
      otpError: false,
      phoneError: false,
      selectedPayment: 'credit_card',
      disabledButton: true,
      showTooltip: false,
      paymentError: false,
      subscriptionExists: false,
      braintreeClientInstance: null,
      creatingBraintreeInstance: false,
      hostedFieldsInstance: null,
      paymentInProgress: false,
      verifyingNumberInProgress: false,
      showSpinner: false,
      subscriptionCanceled: false,
      cancelSubscriptionError: false,
      ltoActive: false,
      showCodeField: false,
      borderRadius: 0,
      translatePhoneInput: null,
      skuus: null,
      confirmLegal: false,
      cardType: '',
      ltoPlan: null,
      cards: [
        {
          card: 'clear-audio',
          description: 'cards.clear_audio_description'
        },
        {
          card: 'transcriptions',
          description: 'cards.transcriptions_description'
        },
        {
          card: 'share',
          description: 'cards.share_description'
        }
      ]
    }
  },
  computed: {
    newLocale:() => {
      return i18n.locale
    },
    filteredSkuus() {
      return this.skuus.filter((skuu) => skuu.indexOf('lto') === -1)
    }
  },
  watch: {
    newLocale: function() {
      this.changeTranslation(this.newLocale)
    }
  },
  created() {
    this.initFirebase()
    this.getSKUUS().then((result) => {
      if(result !== 200) {
        this.selectedPlan = result[1]
        this.skuus = result
        this.skuus.push('bt-trec-grplto-1year-intro-29_99-yearly-59_99')
      }

      this.skuus.forEach((skuu) => {
        if(skuu.indexOf('lto') !== -1) {
          this.ltoPlan = skuu
          let urlQuery = this.$route.query
          if(urlQuery.offer !== undefined && urlQuery.offer === 'lto') this.showLto()
        }
      })
    }).catch((error) => {
      console.error('Could not get skuus', error)
    })
  },
  mounted() {
    window.dataLayer = window.dataLayer || []

    window.addEventListener('keyup',(e) => {
      if(e.keyCode === 13) {
        if(this.showCodeField) {
          this.verifyOtp()
        } else {
          this.setStepThree()
        }
      }
    })

    //show LTO logic
    window.onmouseout=(event) => { 
      if(event.toElement === null) this.showLto()
    }
  },
  destroyed() {
    window.removeEventListener('keyup',(e) => {
      if(e.keyCode === 13) {
        if(this.showCodeField) {
          this.verifyOtp()
        } else {
          this.setStepThree()
        }
      }
    })
  },
  methods: {
    ...mapActions([
      'getSKUUS',
      'getPP',
      'getTerms',
      'loginSignupUser',
      'submitPayment',
      'getReceipt',
      'fetchSubscriptions',
      'cancelSubscription',
      'fetchUUID',
      'acceptTerms'
    ]),
    ...mapGetters([
      'getActiveSubscriptions',
      'getCountryCode',
      'getOnlyCountries',
      'getSKUUSArray',
      'getUserToken',
      'getUUID'
    ]),
    ...mapMutations([
      'setFirebaseUID'
    ]),
    getRate(skuu) {
      if(!skuu) return
    
      if(skuu.indexOf('yearly') !== -1) {
        return i18n._vm.messages[this.newLocale].funnel.annual
      } else if(skuu.indexOf('monthly') !== -1){
        return i18n._vm.messages[this.newLocale].funnel.monthly
      } else {
        return ''
      }
    },
    getPrice(skuu) {
      if(!skuu) return

      let skuuArray = skuu.split('-')
      if(skuu === this.ltoPlan) {
        return skuuArray[skuuArray.length - 3].split("_").join(".")
      } else {
        return skuuArray[skuuArray.length - 1].split("_").join(".")
      }
    },
    getLtoPrice(skuu) {
      if(!skuu) return

      let skuuArray = skuu.split('-')
      return skuuArray[skuuArray.length - 3].split("_").join(".")
    },
    getLtoNormalPrice(skuu) {
      if(!skuu) return

      let skuuArray = skuu.split('-')
      return skuuArray[skuuArray.length - 1].split("_").join(".")
    },
    getTime(skuu) {
      if(!skuu) return ''
    
      if(skuu.indexOf('yearly') !== -1) {
        return i18n._vm.messages[this.newLocale].funnel.year
      } else if(skuu.indexOf('monthly') !== -1){
        return i18n._vm.messages[this.newLocale].funnel.month
      } else {
        return ''
      }
    },
    getTrialDays(skuu) {
      if(!skuu) return
        
      let skuuArray = skuu.split("-")
      return skuuArray[skuuArray.length - 4].split('day')[0]
    },
    getTrialEnd(skuu) {
      let date = new Date()
      let days = this.getTrialDays(skuu)
      date.setDate(date.getDate() + parseInt(days))
      return date.toLocaleDateString("en-US", { "year": "numeric", "month": "long", "day": "numeric" })
    },
    isYearly(skuu) {
      if(!skuu) return ''
    
      if(skuu.indexOf('yearly') !== -1) {
        return true
      } else {
        return false
      }
    },
    changeTranslation(locale) {
      switch(locale) {
        case 'en': 
          this.translatePhoneInput = null
          break
        case 'de': 
          this.translatePhoneInput = {
            countrySelectorLabel: 'Landesvorwahl',
            countrySelectorError: 'Wähle ein Land',
            phoneNumberLabel: 'Telefonnummer',
            example: 'Beispiel:'
          }
          break
        case 'es': 
          this.translatePhoneInput = {
            countrySelectorLabel: 'Código de país',
            countrySelectorError: 'Elige un pais',
            phoneNumberLabel: 'Número de teléfon',
            example: 'Ejemplo:'
          }
          break
        case 'ja': 
          this.translatePhoneInput = {
            countrySelectorLabel: '国コード',
            countrySelectorError: '国を選択します',
            phoneNumberLabel: '電話番号',
            example: '例:'
          }
          break
        case 'zh-cn': 
          this.translatePhoneInput = {
            countrySelectorLabel: '国家代码',
            countrySelectorError: '选择一个国家',
            phoneNumberLabel: '电话号码',
            example: '例子:'
          }
          break
        default: 
          this.translatePhoneInput = null
      }
    },
    updatePhoneNumber(value) {
      this.formattedPhoneNumber = value.formattedNumber
    },
    initFirebase(){
      if (!firebase.apps.length) {
        firebase.initializeApp({
          apiKey: "AIzaSyApnqBy3JhfbpMGo-7DJcwwswDsMh1tCnA",
          authDomain: window.location.hostname,
          databaseURL: "https://tapeacall-145617.firebaseio.com/",
          projectId: "tapeacall-145617",
          storageBucket: "user-upload-holding",
          messagingSenderId: "98361385677",
          appId: "1:98361385677:web:75e12f1a58966e5c7d3f84"
        })

        //set firebase auth lang based on users selected language
        firebase.auth().languageCode = i18n.locale
      }
    },
    verifyOtp(){
      if(this.firebaseOTP.trim().length === 0) {
        this.otpError = true
        return
      }

      //show spinner
      this.showSpinner = true
      window.confirmationResult.confirm(this.firebaseOTP).then((result) => {
        // User signed in successfully. Get the IDToken and hash PP and ToS and send it to the backend
        let user = result.user
        this.setFirebaseUID(user.uid)
        user.getIdToken().then((idToken) => {
          this.loginSignupUserMethod(idToken)
        }).catch((error)=> {
          console.error('Error getting ID Token', error)
        })
      }).catch((error) => {
        // User couldn't sign in (bad verification code?) but now we know the number is not valid
        console.error('Invalid phone number', error)
        this.otpError = true
        this.showSpinner = false
      })
    },
    loginSignupUserMethod(idToken) {
      this.loginSignupUser(idToken).then((newUser) => {
        if(newUser) {
          this.sendSignupEvent()
        }
        this.startPaywall()
      }).catch((statusCode) => {
        if(statusCode === 451) {
          this.getPP()
          this.getTerms()
          this.confirmLegal = true
          this.showSpinner = false
        } else {
          this.otpError = true
          this.showSpinner = false
        }
      })
    },
    sendSignupEvent() {
      this.fetchUUID().then((uuid) => {
        //send GTM event user signed up successfully if he is a new user
        window.dataLayer.push({
          'event': 'gtag_event',
          'event_action': 'signup',
          'event_label': 'user_signup',
          'UserID': uuid
        })
      }).catch((error) => {
        console.error('Error getting UUID', error)
      })
    },
    startPaywall() {
      this.$nextTick(() => {
        this.getReceipt().then((response) => {
          if(response.data.recordings && response.data.recordings.is_active && response.data.transcriptions && response.data.transcriptions.is_active) {
            this.showSpinner = false
            this.$router.push('dashboard')
          } else {
            this.openPaywall()
          }
        }).catch((statusCode) => {
          if(statusCode === 451) {
            this.showSpinner = false
            this.getPP()
            this.getTerms()
            this.confirmLegal = true
          } else {
            this.openPaywall()
          }
        })
      })
    },
    acceptUpdatedTerms() {
      this.acceptTerms().then(() => {
        this.confirmLegal = false
      }).catch((error) => {
        console.error('Terms not accepted', error)
      })
    },
    openPaywall() {
      if(!this.creatingBraintreeInstance) {
        this.creatingBraintreeInstance = true
        let token = window.location.hostname === 'app.tapeacall.com' ? 'production_5rdpg6m4_77sbmrpcbx3mjstj' : 'sandbox_jy9qktf7_69cm62d9n98mbq7k'
        braintree.client.create({
          authorization: token
        },(err, clientInstance) => {
          if (err) {
            console.error('error creating client instance', err)
            return
          }
          this.braintreeClientInstance = clientInstance
          this.createCreditCardPayment(clientInstance)
          this.showCodeField = false
          this.activeStep = 3
          this.showSpinner = false
        })
      }
    },
    initReCaptcha(){
      setTimeout(()=>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback':() => {},
          'expired-callback':() => {}
        })
        this.appVerifier = window.recaptchaVerifier
      },1000)
    },
    hadLto() {
      let hadLto = false

      this.getActiveSubscriptions().forEach((entry) => {
        if(entry.product_id.indexOf('lto') !== -1) {
          hadLto = true
        }
      })

      return hadLto
    },
    showLto() {
      let lto = localStorage.getItem(LTO)
      if(lto === null && !lto && this.ltoPlan !== null && !this.hadLto()) {
        this.ltoActive = true
      }
    },
    dismissLto() {
      this.ltoActive = false
      localStorage.setItem(LTO, true)
    },
    acceptLto() {
      //set current plan to lto
      this.selectedPlan = this.ltoPlan
      this.dismissLto()
      this.selectedPayment = 'credit_card'
      this.setStepTwo()
    },
    hasTrial(skuu) {
      if(!skuu) {
        skuu = this.selectedPlan
      }
      //check if plan has trial
      if(skuu && skuu.indexOf('notrial') === -1 && skuu.indexOf('trial') !== -1) {
        return true
      }
      return false
    },
    changePaymentMethod() {
      this.paymentError = false
      switch(this.selectedPayment) {
        case 'credit_card':
          this.createCreditCardPayment(this.braintreeClientInstance)
          break
        case 'paypal':
          this.createPayPalPayment(this.braintreeClientInstance)
          break
        case 'google_pay': 
          this.createGooglePayPayment(this.braintreeClientInstance)
          break
        case 'apple_pay':
          this.createApplePayPayment(this.braintreeClientInstance)
          break
        default:
          this.createCreditCardPayment(this.braintreeClientInstance)
      }
    },
    createCreditCardPayment(clientInstance) {
      this.$nextTick(() => {
        const self = this
        let state = null

        braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            'input': {
              'font-size': '16px',
              'font-family': 'sans-serif',
              'padding': '0px 10px'
            },
            ':focus': {
              'color': 'black',
              'border': '1px solid blue'
            },
            '.valid': {
              'color': 'black'
            },
            '.invalid': {
              'color': 'red'
            },
            // Media queries
            // Note that these apply to the iframe, not the root window.
            '@media screen and (max-width: 700px)': {
              'input': {
                'font-size': '14px'
              }
            }
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: '1111 1111 1111 1111'
            },
            cvv: {
              selector: '#cvv',
              placeholder: '3 digit or 4 digit'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YY'
            },
            postalCode: {
              selector: '#cardholder-zip',
              placeholder: 'ZIP'
            }
          }
        },(err, hostedFieldsInstance) => {
          if (err) {
            console.error('error creating hosted fields', err)
            self.paymentError = true
            return
          }

          self.creatingBraintreeInstance = false

          const ccName = $('#cardholderName')
          const email =  $('#cardholderEmail')
          const submit = $('#submit-button')

          function setValidityClasses(element, validity) {
            if (validity) {
              element.removeClass('error')
              element.addClass('valid')  
            } else {
              if(element.val() !== '') {
                element.addClass('error')
                element.removeClass('valid')
              }
            }    
          }
              
          function validateInput(element) {
            if (element.val() && !element.val().trim()) {
              setValidityClasses(element, false)
              return false
            }

            setValidityClasses(element, true)
            return true
          }

          function validateEmail() {
            let baseValidity = validateInput(email)
            if (!baseValidity) {
              return false
            }

            if (!self.validEmail(email.val())) {
              setValidityClasses(email, false)
              return false
            }
            setValidityClasses(email, true)
            return true
          }

          ccName.on('keyup',() => {
            removeFocus()
            validateInput(ccName)
            canSubmit()
          })

          email.on('keyup',() => {
            removeFocus()
            validateEmail(email)
            canSubmit()
          })

          hostedFieldsInstance.on('focus',(event) => {
            removeFocus()
            let emmited = event.emittedBy
            if(emmited === 'number') {
              $('#card-number').addClass('focus')
            } else if(emmited === 'cvv') {
              $('#cvv').addClass('focus')
            }
          })

          function removeFocus() {
            $('#card-number').removeClass('focus')
            $('#cvv').removeClass('focus')
          }

          hostedFieldsInstance.on('validityChange',(event) => {
            let field = event.fields[event.emittedBy]

            //Remove any previously applied error or warning classes
            $(field.container).removeClass('error')
            $(field.container).removeClass('valid')

            if (field.isValid) {
              $(field.container).addClass('valid');
            } else {
              $(field.container).addClass('error');
            }
            canSubmit()
          })

          hostedFieldsInstance.on('cardTypeChange',(event) => {
            if (event.cards.length === 1) {
              self.cardType = event.cards[0].type
            } else {
              self.cardType = ''
            }
          })

          function canSubmit() {
            state = hostedFieldsInstance.getState()
            let canContinue = true
            Object.keys(state.fields).forEach((field) => {
              if (!state.fields[field].isValid || !validateInput(ccName) || !validateEmail(email)) {
                canContinue = false
              }
            })

            if(canContinue) {
              self.disabledButton = false
            } else {
              self.disabledButton = true
            }
          }

          //submit data
          submit.click((e) => {
            e.preventDefault()
            if(self.paymentInProgress) {
              return
            }

            self.paymentInProgress = true

            //reset payment error
            self.paymentError = false

            //set hostedFieldsInstance if needed for deleting data in form
            //self.hostedFieldsInstance = hostedFieldsInstance

            hostedFieldsInstance.tokenize({
              //include cardholder name into request
              cardholderName: ccName.val()
            }, (err, payload) => {
              if(err) {
                console.error(err)
                self.paymentError = true
                self.paymentInProgress = false
                return
              }
              self.startCheckout(payload.nonce, {
                email: email.val(),
                name: ccName.val(),
                hasName: true
              }, null, null)
            })
          })
        })
      })
    },
    createPayPalPayment(clientInstance) {
      const self = this

      //avoid weird google play button rendering
      $('.google-pay-btn-holder').html('')

      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create({
        client: clientInstance
      }, (paypalCheckoutErr, paypalCheckoutInstance) => {
        if (paypalCheckoutErr) {
          console.error('Error creating paypal client:', paypalCheckoutErr)
          self.paymentError = true
          return
        }

        let billingAgreement = self.hasTrial() ? `After your ${self.getTrialDays(self.selectedPlan)}-day FREE trial, your recurring ${self.isYearly(self.selectedPlan) ? "Yearly" : "Monthly"} Subscription of $${self.getPrice(self.selectedPlan)}/${self.isYearly(self.selectedPlan) ? "yr" : "mo"} begins on ${self.getTrialEnd(self.selectedPlan)}. Cancel anytime.` : 'TapeACall recurring subscription'

        // Set up PayPal with the checkout.js library
        paypal.Button.render({
          env: window.location.hostname === 'app.tapeacall.com' ? 'production' : 'sandbox',
          style: {
            size: 'responsive',
            color: 'blue',
            shape: 'rect',
            label: 'checkout',
            tagline: 'false'
          },
          payment:() => {
            return paypalCheckoutInstance.createPayment({
              "flow": "vault",
              "displayName": "TapeACall",
              "billingAgreementDescription": billingAgreement
            });
          },
          onAuthorize:(data, actions) => {
            self.paymentError = false
            return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
              if(err) {
                console.error('Error tokenizing paypal', err, actions)
                self.paymentError = true
                return
              }

              self.paymentError = false
              self.startCheckout(payload.nonce, {
                email: payload.details.email,
                name: `${payload.details.firstName} ${payload.details.lastName}`,
                hasName: true
              }, null, null)
            })
          },
          onCancel:(data) => {
            self.paymentError = true
            console.error('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
          },
          onError:(err) => {
            self.paymentError = true
            console.error('checkout.js error', err);
          }
        }, '#paypal-button').then(() => {})
      })
    },
    createGooglePayPayment(clientInstance) {
      const self = this
      const google = window.google
      let paymentsClient = new google.payments.api.PaymentsClient({
        environment: window.location.hostname === 'app.tapeacall.com' ? 'PRODUCTION' : 'TEST',
      })

      braintree.googlePayment.create({
        client: clientInstance,
        googlePayVersion: 2,
        googleMerchantId: 'BCR2DN6TWOYINEKU'
      }, (googlePaymentErr, googlePaymentInstance) => {

        if(googlePaymentErr) {
          console.error('Error creating googlePlayInstance:', googlePaymentErr)
          self.paymentError = true
          return
        }

        paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods[0]],
          existingPaymentMethodRequired: true
        }).then((response) => {
          if(response.result) {
            let gpayBtn = paymentsClient.createButton({
              onClick:(e) => {
                e.preventDefault();
                let paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                  transactionInfo: {
                    currencyCode: 'USD',
                    totalPriceStatus: 'FINAL',
                    totalPrice: self.hasTrial() ? '0.00' : self.getPrice(self.selectedPlan)
                  },
                  emailRequired: true
                })
                    
                paymentDataRequest.allowedPaymentMethods = [googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods[0]];
                paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
                  googlePaymentInstance.parseResponse(paymentData, (err, result) => {
                    if(err) {
                      self.paymentError = true
                      console.error('GPay Error: ' + err)
                    }
                    self.paymentError = false
                    self.startCheckout(result.nonce, {
                      email: paymentData.email,
                      hasName: false
                    }, null, null)
                  })
                }).catch((err) => {
                  self.paymentError = true
                  console.error('GPay Error: ' + err)
                })
              },
              buttonSizeMode: 'fill'
            })
            //render google pay button
            $('.google-pay-btn-holder').html(gpayBtn)
          }
        }).catch((err) => {
          self.paymentError = true
          console.error('GPay Error: ' + err)
        })
      })
    }, 
    createApplePayPayment(clientInstance) {
      const self = this

      //avoid weird google play button rendering
      $('.google-pay-btn-holder').html('')

      braintree.applePay.create({
        client: clientInstance
      },(applePayErr, applePayInstance) => {
        if(applePayErr) {
          console.error('Error creating applePayInstance:', applePayErr)
          self.paymentError = true
          return
        }

        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
          console.error('ApplePay Error - Cannot make Payments')
          self.paymentError = true
          return
        }

        let promise = window.ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
        promise.then((canMakePaymentsWithActiveCard) => {
          if(canMakePaymentsWithActiveCard) {
            $("#apple-pay-start").show()
            $("#apple-pay-start").on('click',() => {
              tokenizeApplePay(applePayInstance)
            })
          } else {
            self.paymentError = true
            console.error('ApplePay is not possible in this browser')
          }
        }).catch((error) => {
          console.error('error creating apple payment', error)
          $("#apple-pay-start").hide()
          self.paymentError = true
        })
      })

      function tokenizeApplePay(applePayInstance) {
        let lineItemsArray = [
          {
            "label": `TapeACall ${self.isYearly(self.selectedPlan) ? "Yearly" : "Monthly"}`,
            "amount": self.getPrice(self.selectedPlan),
            "type": "final"
          }
        ]
        if(self.hasTrial()) {
          lineItemsArray.push({
            "label": `After your ${self.getTrialDays(self.selectedPlan)}-day FREE trial, your recurring ${self.isYearly(self.selectedPlan) ? "Yearly" : "Monthly"} Subscription of $${self.getPrice(self.selectedPlan)}/${self.isYearly() ? "yr" : "mo"} begins on ${self.getTrialEnd(self.selectedPlan)}. Cancel anytime.`,
            "amount": "0.00",
            "type": "final"
          })
        }
        let paymentRequest = applePayInstance.createPaymentRequest({
          lineItems: lineItemsArray,
          total: {
            "label": `TapeACall`,
            "amount": self.hasTrial() ? "0.00" : self.getPrice(self.selectedPlan),
            "type": "final"
          },
          requiredShippingContactFields: ["name", "email"],
          requiredBillingContactFields: ["postalAddress"]
        })

        let session = new window.ApplePaySession(3, paymentRequest)
        session.onvalidatemerchant = (event) => {
          applePayInstance.performValidation({
            validationURL: event.validationURL,
            displayName: 'TapeACall'
          },(err, merchantSession) => {
            if (err) {
              console.error('Error validating merchant', err)
              self.paymentError = true
              return
            }
            session.completeMerchantValidation(merchantSession)
          })
        }

        session.onpaymentauthorized = (event) => {
          applePayInstance.tokenize({
            token: event.payment.token
          },(tokenizeErr, payload) => {
            if (tokenizeErr) {
              console.error('Error tokenizing Apple Pay:', tokenizeErr)
              session.completePayment(window.ApplePaySession.STATUS_FAILURE)
              self.paymentError = true
              return
            }

            self.startCheckout(payload.nonce, {
                email: event.payment.shippingContact.emailAddress,
                name: event.payment.shippingContact.givenName + " " + event.payment.shippingContact.familyName,
                hasName: true
              },
              function() {
                // After you have transacted with the payload.nonce,
                // call `completePayment` to dismiss the Apple Pay sheet.
                session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
              },
              function() {
                session.completePayment(window.ApplePaySession.STATUS_FAILURE)
            })
          })
        }

        session.begin()
      }
    },
    startCheckout(nonce, userData, successFn, failureFn) {
      this.paymentInProgress = true
      let paymentObject = {
        nonce: nonce,
        firstName: userData.hasName ? userData.name.split(" ")[0] : '',
        lastName: userData.hasName ? userData.name.split(" ")[1] : '',
        emailAddress: userData.email,
        skuuID: this.selectedPlan
      }

      this.submitPayment(paymentObject).then(() => {
        //save hostedFieldsInstance in data object and delete input fields
        // if(this.hostedFieldsInstance) {
        //   this.hostedFieldsInstance.clear('number')
        //   this.hostedFieldsInstance.clear('cvv')
        //   this.hostedFieldsInstance.clear('expirationDate')
        // }

        if(successFn) {
          successFn()
        }

        //send GTM event user checkout success
        if(this.getUUID()) {
          this.fetchSubscriptions().then(() => {
            if(this.getActiveSubscriptions().length > 0) {
              if(this.hasTrial()) {
                window.dataLayer.push({
                  'event': 'gtag_event',
                  'event_action': 'checkout_completed_trial',
                  'event_label': 'user_checkout_completed_trial',
                  'UserID': this.getUUID(),
                  'SubscriptionID': this.getActiveSubscriptions()[0].subscription_id,
                })
              } 

              window.dataLayer.push({
                'event': 'gtag_event',
                'event_action': 'checkout_completed',
                'event_label': 'user_checkout_completed',
                'UserID': this.getUUID(),
                'SubscriptionID': this.getActiveSubscriptions()[0].subscription_id,
              })
            }

            this.getReceipt().then(() => {
              this.$router.push('dashboard')
            })
          })
        } else {
          this.getReceipt().then(() => {
            this.$router.push('dashboard')
          })
        }
      }).catch((err) => {
        console.error('error while checkout', err)
        if(failureFn) {
          failureFn()
        }
        if(err.response.data && err.response.data.indexOf('subscription') !== -1 && err.response.data.indexOf('exists') !== -1) {
          this.subscriptionExists = true
        } else {
          this.paymentError = true
        }
        this.paymentInProgress = false
      })
    },
    setStepOne() {
      this.activeStep = 1
    },
    setStepTwo() {
      if(this.getUserToken() === null) {
        this.activeStep = 2
        if(this.appVerifier === '') {
          this.initReCaptcha()
        }
      } else {
        this.startPaywall()
      }
    },
    setStepThree() {
      //prevent multiple verifying
      if(this.verifyingNumberInProgress) return

      this.phoneError = false
      this.otpError   = false

      if(!this.formattedPhoneNumber || this.formattedPhoneNumber === '') {
        this.phoneError = true
        return
      }

      this.verifyingNumberInProgress = true

      firebase.auth().signInWithPhoneNumber(this.formattedPhoneNumber, this.appVerifier)
        .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message
        // Usually we sign the user in with confirmationResult.confirm(code) but we only need to confirm the number for now
        window.confirmationResult = confirmationResult
        this.showCodeField = true
        this.verifyingNumberInProgress = false
      }).catch((error) => {
        console.error('SMS not sent', error)
        this.showCodeField = false
        this.phoneError = true
        this.verifyingNumberInProgress = false
      })
    },
    validEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email)
    },
    isSafariBrowser(){
      let is_chrome = navigator.userAgent.indexOf('Chrome') > -1
      let is_safari = navigator.userAgent.indexOf('Safari') > -1
      if (is_safari){
        if (is_chrome)
          return false
        else
          return true
      }
      return false
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_funnel";
</style>
