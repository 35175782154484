<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">{{ lang.full }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return { 
      langs: [
        {
          code: 'en',
          full: 'English'
        },
        // {
        //   code: 'de',
        //   full: 'Deutsch'
        // },
        // {
        //   code: 'es',
        //   full: 'Español'
        // },
        // {
        //   code: 'es-es',
        //   full: 'Español (España)'
        // },
        // {
        //   code: 'fr',
        //   full: 'Français'
        // },
        // {
        //   code: 'he',
        //   full: 'עברית'
        // },
        // {
        //   code: 'it',
        //   full: 'Italiano'
        // },
        // {
        //   code: 'ja',
        //   full: '日本語'
        // },
        // {
        //   code: 'nl',
        //   full: 'Nederlands'
        // },
        // {
        //   code: 'pt-br',
        //   full: 'Português (Brasil)'
        // },
        // {
        //   code: 'ru',
        //   full: 'Русский'
        // },
        // {
        //   code: 'sv',
        //   full: 'Svenska'
        // },
        // {
        //   code: 'tr',
        //   full: 'Türkçe'
        // },
        // {
        //   code: 'zh-cn',
        //   full: '繁体中文'
        // },
        // {
        //   code: 'zh-hk',
        //   full: '繁体中文 (香港)'
        // }
      ] 
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_switcher";
</style>