<template>
	<footer>
		<div class="footer-wrapper">
			<div class="footer-column first">
				<router-link to="/" class="logo">
					<img src="@/assets/images/footer/TapeACallLogoWhite.png" alt="TapeACall logo">
				</router-link>
				<p class="copyright">© {{ new Date().getFullYear() }} TapeACall. {{ $t('footer.all_rights') }} <br>{{ $t('footer.made_by') }} <a href="https://teltech.co" target="_blank" rel="noopener noreferrer">TelTech</a>.</p>
				<div class="language-switcher">
					<div :class="['flag', $i18n.locale]"></div>
					<Switcher />
				</div>
			</div>
			<div class="footer-column second">
				<span>{{ $t('footer.learn_more') }}</span>
				<router-link to="/">{{ $t('footer.home') }}</router-link>
				<a href="https://tapeacall.com/blog" target="_blank" rel="noopener noreferrer">{{ $t('navbar.blog') }}</a>
			</div>
			<div class="footer-column fourth">
				<span>{{ $t('footer.about') }}</span>
				<a href="https://tapeacall.com/support" target="_blank" rel="noopener noreferrer">{{ $t('navbar.help') }}</a>
				<a href="https://teltech.co/privacy.html" target="_blank"  rel="noopener noreferrer">{{ $t('navbar.privacy') }}</a>
				<a href="https://teltech.co/terms.html" target="_blank"  rel="noopener noreferrer">{{ $t('navbar.terms') }}</a>
				<a href="#" class="ot-sdk-show-settings">Do Not Sell or Share my Personal Information</a>
			</div>
			<div class="footer-column third">
				<span>{{ $t('footer.get_in_touch') }}</span>
				<a href="https://tapeacall.com/support" target="_blank" rel="noopener noreferrer">{{ $t('navbar.contact') }}</a>
				<div class="social-icons">
					<a href="https://www.facebook.com/TapeACall/" target="_blank" rel="noopener noreferrer">
						<div class="facebook"></div>
					</a>
					<a href="https://twitter.com/tapeacall" target="_blank" rel="noopener noreferrer">
						<div class="twitter"></div>
					</a>
					<!-- <a href="#" target="_blank" rel="noopener noreferrer">
						<div class="instagram"></div>
					</a> -->
				</div>
			</div>
			<div class="footer-column fifth">
				<span>{{ $t('navbar.download') }}</span>
				<div class="download-links">
					<a href="https://apps.apple.com/us/app/tapeacall-call-recorder/id573751328" class="download-link app-store" target="_blank" rel="noopener noreferrer">
						<div :aria-label="$t('download.download_ios')"></div>
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.tapeacall.com&amp;hl=en_US&amp;gl=US" class="download-link play-store"  target="_blank" rel="noopener noreferrer">
						<div :aria-label="$t('download.download_android')"></div>
					</a>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
import Switcher from './LanguageComponent.vue'
export default {
	name: 'Footer',
	components: {
		Switcher
	}
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_footer";
</style>