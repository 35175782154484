<template>
  <div class="home">
    <FunnelComponent :title="$t('funnel.title')" />
  </div>
</template>

<script>
import FunnelComponent from '@/components/FunnelComponent'
export default {
  name: 'Home',
  components: {
    FunnelComponent
  }
}
</script>
