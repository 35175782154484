import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
//import GTM from 'vue-gtm'

Vue.config.productionTip = false

// Vue.use(GTM, {
//   id: 'GTM-TCK8KMH',
//   defer: false,
//   enabled: true,
//   debug: true,
//   loadScript: true,
//   vueRouter: router,
//   trackOnNextTick: false,
// })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
