<template>
  <section class="pt-60">
    <div class="cards-wrapper" v-if="cards.length > 0">
      <div class="card" v-for="(card, index) in cards" :key="'card-' + index">
        <div :class="card.card"></div>
        <p class="white">{{ $t(card.description) }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    cards: Array
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_cards";
</style>
