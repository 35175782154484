import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/NewDashboard.vue')
  },
  // {
  //   path: '/recorder',
  //   name: 'Recorder',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import('../views/Recorder.vue')
  // },
  { 
    path: '*', 
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['getUserToken']) {
      next()
      return
    }
    next('/login')
  }
  if ((to.path == '/' || to.path == '/login') && store.getters['getUserToken']) {
    next('/dashboard')
    return
  }
  next()
})

export default router
