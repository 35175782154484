<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div :class="['modal-container', className]">
          <!-- <svg class="close modal-close" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M24 1.414L22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12z"/>
          </svg> -->
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <Button :label="$t('dashboard.recordings.close')" @click.native="$emit('confirm')" v-if="!choice && className === 'qr-modal'" />
          <Button :label="$t('funnel.accept')" @click.native="$emit('confirm')" v-if="!choice && className !== 'qr-modal'" />
          <div class="choice" v-if="choice">
            <Button :label="$t('dashboard.no')" @click.native="$emit('close')"/>
            <Button :label="$t('dashboard.yes')" @click.native="$emit('confirm')"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button'
export default ({
  name: 'ModalComponent',
  props: {
    choice: {
      default: false,
      type: Boolean
    },
    className: {
      default: '',
      type: String
    }
  },
  components: {
    Button
  }
})
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_modal";
</style>
