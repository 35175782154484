<template>
	<div :class="['navbar', {'open-menu': open}]">
		<div :class="['navbar-container', {'open-menu': open}]">
			<router-link to="/" class="logo">
				<img src="@/assets/images/TapeACallLogo.svg" alt="TapeACall brand logo">
			</router-link>
			<nav role="navigation" class="navigation dashboard" v-if="$route.name === 'Dashboard' || $route.name === 'Upload'">
				<router-link class="active" to="/dashboard" @click.native="open = false">{{$t('dashboard.dashboard')}}</router-link>
				<!-- <router-link class="active" to="/recorder" @click.native="open = false">{{$t('dashboard.recorder')}}</router-link> -->
				<a href="#" :class="['mobile-anchor', {'active' : getActiveTab() === 'invoices'}]" @click.prevent="setActiveTab('invoices'); open = false">{{ $t('dashboard.header.invoices')}}</a>
				<a href="#" :class="['mobile-anchor', {'active' : getActiveTab() === 'subscriptions'}]" @click.prevent="setActiveTab('subscriptions'); open = false">{{ $t('dashboard.header.subscriptions')}}</a>
				<div class="horizontal-divider"></div>
				<a href="#" :class="{'highlight' : getHighlightQR }" @click.prevent="setShowQRCodes(true)">QR Download codes</a>
				<a href="#" class="logout-button" @click.prevent="logoutMethod()">
					<Button :label="$t('dashboard.logout')" :disabled="'disabled'" />
				</a>
			</nav>
			<nav role="navigation" class="navigation" v-else>
				<a href="https://tapeacall.com/support" target="_blank" rel="noopener noreferrer">{{$t('navbar.contact')}}</a>
				<a href="https://tapeacall.com/blog" target="_blank" rel="noopener noreferrer">{{$t('navbar.blog')}}</a>
				<a href="https://tapeacall.com/support" target="_blank" rel="noopener noreferrer">{{$t('navbar.help')}}</a>
				<a href="https://teltech.co/privacy.html" target="_blank" rel="noopener noreferrer">{{$t('navbar.privacy')}}</a>
				<a href="https://teltech.co/terms.html" target="_blank" rel="noopener noreferrer">{{$t('navbar.terms')}}</a>
				<a href="https://teltech.co/privacy.html#section-8" target="_blank" rel="noopener noreferrer">{{$t('navbar.californiaPrivacy')}}</a>
				<router-link to="/dashboard" v-if="getUserToken !== null">
					<Button :label="$t('dashboard.dashboard')" />
				</router-link>
				<a id="navbar-download" href="https://tapeacall.com/#download-section-start" target="_blank" rel="noopener noreferrer" v-else>
					<Button :label="$t('navbar.download')" />
				</a>
			</nav>
			<div class="navbar-footer">
				<div class="social-icons">
					<a href="https://www.facebook.com/TapeACall/" target="_blank" rel="noopener noreferrer">
						<div class="facebook"></div>
					</a>
					<a href="https://twitter.com/tapeacall" target="_blank" rel="noopener noreferrer">
						<div class="twitter"></div>
					</a>
				</div>
				<div class="download-column">
					<span>{{ $t('navbar.download') }}</span>
					<div class="download-links">
						<a href="https://apps.apple.com/us/app/tapeacall-call-recorder/id573751328" class="download-link app-store" target="_blank" rel="noopener noreferrer">
							<div :aria-label="$t('download.download_ios')"></div>
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.tapeacall.com&amp;hl=en_US&amp;gl=US" class="download-link play-store"  target="_blank" rel="noopener noreferrer">
							<div :aria-label="$t('download.download_android')"></div>
						</a>
					</div>
				</div>
			</div>
			<div :class="['menu-button',{'open': open}]" aria-label="menu" role="button" tabindex="0" aria-haspopup="menu" :aria-expanded="open ? 'true' : 'false'" @click="open = !open">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
</template>
<script>
import Button from './Button'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'Navbar',
	components: {
      Button
	},
	computed: {
		...mapGetters([
			'getUserToken',
			'getReceipts',
			'getHighlightQR'
		])
	},
	data() {
		return {
			open: false
		}
  },
  methods: {
	...mapActions([
		'logout'
	]),
    ...mapGetters([
		'getActiveTab'
	]),
	...mapMutations([
		'setActiveTab',
		'setShowQRCodes'
	]),
	handleResize() {
		this.open = false
	},
	logoutMethod() {
		this.logout().then(() =>{
			this.$router.push('login')
		})
	}
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_navbar";
</style>