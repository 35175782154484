<template>
  <div id="app" :class="{'fixed': getMobileShowFilterLists()}">
    <div class="backdrop" v-if="getMobileShowFilterLists()"></div>
    <Navbar />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
const LAST_LOGIN = 'last_visited'

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    this.setMobileShowFilterLists(false)
    this.getIP()
    this.getPP()
    this.getTerms()
    let lastLogin = localStorage.getItem(LAST_LOGIN)
    const ONE_HOUR = 60 * 60 * 1000
    let now = new Date()

    if(lastLogin !== null && lastLogin !== undefined && ((now.getTime() - parseInt(lastLogin)) >= ONE_HOUR)) {
      this.logout().then(() => {
        this.$router.push('login')
      })
    } 
  },
  methods: {
    ...mapGetters([
      'getMobileShowFilterLists'
    ]),
    ...mapMutations([
      'setMobileShowFilterLists'
    ]),
    ...mapActions([
      'logout',
      'getIP',
      'getPP',
      'getTerms'
    ])
  }
}
</script>
<style lang="scss">
@import "assets/scss/app";
</style>